import {
    carousel,
    carouselItem,
    Row,
    Col,
    Input,
    Form,
    FormItem,
    Button,
    Message,
    Menu,
    MenuItem,
    Submenu,
    Dialog
} from 'element-ui'
const element = {
    install: function (Vue) {
        Vue.use(carousel)
        Vue.use(carouselItem)
        Vue.use(Row)
        Vue.use(Col)
        Vue.use(Input)
        Vue.use(Button)
        Vue.use(Form)
        Vue.use(FormItem)
        Vue.use(Dialog)
        Vue.prototype.$message = Message;
        Vue.use(Menu)
        Vue.use(MenuItem)
        Vue.use(Submenu)
    }
}
export default element

