
export default {
    // 导航
    navigator: {
        menu: [
            { title: 'What We Do', children: [] ,index:'/solution'},
            { title: 'New Room', children: [], index: '/news-room' },
            { title: 'Who We Are', children: [], index: '/about-us' },
            { title: 'Get In Touch', children: [], index: '/contact' },
        ],
    },
    home: {
        carouseTop: {
            bgText1: 'Make Life Simple',
            bgText2: 'Through Advertising',
            bgText3: 'Global Mobile Performance',
            bgText4: 'Advertising Experts',
            bgText5: 'Gateway To',
            bgText6: 'New Emerging Markets',
        },
        carouseMid: [
            {
                title: 'Programmatic',
                index: '0',
                title1: 'Self-developed RTB platform',
                title2: 'Global premium publishers',
            },
            {
                title: 'UA Audit',
                index: '1',
                title1: 'Multi-category & platform Ads experience',
                title2: 'Professional result-oriented optimization services',
            },
            {
                title: 'Creative Lab',
                index: '2',
                title1: 'Result-oriented approach',
                title2: 'Creativity, Versatility, Localization',
            },
            {
                title: 'New Media',
                index: '3',
                title1: 'Emerging Media Core Reseller',
                title2: 'Differentiated Media Resources',
            },
            {
                title: 'Monetization',
                index: '4',
                title1: 'Multiple platform integration options',
                title2: 'One-stop monetization services',
            },
        ],
        solutionTitle: 'What We Do',
        customerTitle: 'Trusted By',
        mediaTitle: "Traffic Partners",
    },
    footer: {
        solutionTitle: 'What We Do',
        platName: 'Programmatic',
        mediaAgency: 'UA Audit',
        creativeMaterial: 'Creative Lab',
        emergingMedia: 'New Media',
        realizationPlatform: 'Monetization',
        latestNews: 'News Room',
        aboutUs: 'Who We Are',
        aboutNova: 'About Novabeyond',
        joinUs: 'Join Us',
        privacyPolicy:"Privacy Policy",
        concate: 'Get In Touch',
    },
    news: {
        bgTitle: 'Blog & Event',
        event: "Event",
        blog: "Blog",
        blogOfBigo: "NOVABEYOND obtained the Official reseller qualification of BIGOAds",
        blogOfBigoIntro: "NOVABEYOND officially formed strategic partnership width BigoAds,proving that our industry influence,comprehensive promotion and marketing ability,and comprehensive business ability have been offivially recognized by BIGO.",
        blogOfKwai: "NOVABEYOND obtained the core agent qualification of Kuaishou",
        blogOfKwaiIntro: "On December 31,2021,NOVABEYOND received a partner authorization letter from Kuaishou overseas advertising,which has become a powerful help for demestic product developers to open up new markets.",
        bigoAdsParagraph1: "BIGO X Novabeyond Partnership Announcement",
        bigoAdsParagraph2: "On June 11, 2021, NOVABEYOND officially became the core agency of BigoAds, proving that our industry influence, comprehensive promotion and marketing ability, and comprehensive business ability have been officially recognized by BIGO.",
        bigoAdsParagraph3: "A general guide for choosing materials for BIGO Ads",
        bigoAdsParagraph4: "For example, the selection of material for pan-entertainment applications should ensure that the subject is clear, and the video material is optimal within 8~15S. While avoiding exposure and partial enlargement of the body, we can emphasize the body curve. Generally, it is recommended to use 70% of the video material plus 30% of the image material combined.",
        bigoAdsParagraph5: "The difference is that the creative requirements of application tool products are more in line with the functional characteristics, and try to simplify them as much as possible. Induced elements can be added in an appropriate amount, such as 'changes before and after use', 'how does it help life', etc. The application can also use the 3-7 ratios to allocate pictures and video materials.",
        bigoAdsParagraph6: "Financial products are slightly similar to tool products. They also require concise style and clear information, but highlight product characteristics, such as loan amount, term, wealth management income, etc. The selection of material types is a one-to-one allocation of pictures and videos.",
        bigoAdsParagraph7: "Game applications are one of the mainstays in the current overseas industry. In the process of placing relevant advertising materials, more advertisements can be used to reflect the gameplay, attract players with a real game interface, and attract players through elements such as real gold rewards. It is also the most popular model at the moment.",
        bigoAdsParagraph8: "As a high-quality mobile effect marketing service provider in China, Novabeyond has always been committed to providing better overseas marketing services for domestic overseas marketers. With the growth of global traffic, overseas groups continue to grow, and overseas marketing needs are increasing day by day. Novabeyond will explore the diversification of delivery forms and the refinement of materials to help you achieve rapid user growth in the global market with better delivery effects.",
        kwaiBusinessParagraph1: "KWAI For Business X Novabeyond Partnership Announcement",
        kwaiBusinessParagraph2: "Novabeyond has recently announced its global advertising partnership with Kwai for Business as an official marketing partner. As an official marketing partner, Novabeyond will provide advertisers around the world with campaign management and mobile growth<br> opportunities.",
        kwaiBusinessParagraph3: "KWAI has more than 1 billion MAUs for its products, including short video platforms Kuaishou and SnackVideo. Kwai for Business provides marketers with a large, high-quality target audience and powerful spending power. Kwai currently has better product performance than traditional media for some categories and regions.",
        kwaiBusinessParagraph4: "Taking 'Make Life Simple Through Advertising' as the concept mission and development direction, we are committed to changing lives through advertising. Our headquarter is located in Xi'an, China, and we also have local business teams in popular markets around the world.",
        kwaiBusinessParagraph5: "At present, the company's business is mainly divided into two-part, Programmatic Ads & Media buy services. By gathering more traffic from direct developers of In-app and obtaining exclusive cooperation with SDK traffic, the advertising platform can already reach 90M+ daily impressions. It can complete the operation on multiple top overseas media such as Facebook, Google, Bigo Ads, TikTok, Kwai, Snapchat, etc. We hope that through our own services, we can bring customers a unique and customized experience to jointly achieve our market goals.",
        miAds:{
            title:"NOVABEYOND becomes the core agent of MiAds in  China",
            titleInfo:"In June 2022, NOVABEYOND and Xiaomi International Internet Advertising Platform jointly announced that the two parties have officially reached a partnership, and NOVABEYOND will become the core agent of MiAds in China! This official announcement fully expresses MiAds' recognition of the comprehensive strength  of NOVABEYOND",
            paragraph1:"NOVABEYOND becomes the core agent of MiAds",
            paragraph5:"In June 2022, <b>NOVABEYOND</b> and <b>Xiaomi International Internet Advertising Platform</b> jointly announced that the two parties have officially reached a partnership, and NOVABEYOND will become the core agent of MiAds in China! This official announcement fully expresses MiAds' recognition of the comprehensive strength  of NOVABEYOND",
        },
        shortVideo:{
            title:"NOVABEYOND becomes the first-level agent of domestic short video overseas traffic",
            titleInfo:"In recent years, NOVABEYOND has continued to break through itself and seek new opportunities. It has successively obtained the core agency qualifications of BIGO Ads and Kwai For Business, and has become the first-level agent of domestic short video overseas traffic!",
            paragraph1:"<b>NOVABEYOND</b> was established in 2018, and its overseas marketing business was officially launched. Since then, NOVABEYOND has continued to break through itself and seek new opportunities. In recent years, NOVABEYOND has successively obtained the core agency qualifications of <b>BIGO Ads</b> and <b>Kwai For Business</b>, and has become <b>the first-level agency of domestic short video overseas traffic!</b>",
        },
        shareChat:{
            title:"Novabeyond officially became the only core agent of the Sharechat platform in mainland China",
            titleInfo:"Novabeyond will officially become the only core agent of the Sharechat platform in mainland China in 2022. Afterwards, Novabeyond will join hands with Sharechat to provide global advertisers with more accurate and effective promotion solutions, and assist global developers to complete the overseas layout in India.",
            paragraph1:"Novabeyond officially became the core agent of the Sharechat platform",
            paragraph2:"<b>NOVABEYOND</b> will officially become the only core agent of the <b>Sharechat platform</b> in mainland China in 2022. Afterwards, NOVABEYOND will work with Sharechat to provide global advertisers with more accurate and effective promotion solutions, and assist global developers to complete their overseas deployment in India."
        }
    },
    aboutUs: {
        timeLine2018: "Novabeyond officially established our global digital marketing business first time launched.",
        timeLine2019: "India local office established. Obtained core agency qualifications of GoogleAds; Successfully<br> provided innovative technology&product solutions for clients in over 160+ countries & regions <br>around the world.",
        timeLine2020: "Indonesia local office established.",
        timeLine2021: "Obtained core agency qualifications of BIGOAds.",
        timeLine2022: "Obtained core agency qualifications of Kwai&Mi Ads. Africa local office established.",

    },
    solution: {
        programAdverPlat: 'Programmatic',
        trafficPartner: "Traffic Partner",
        averAlgo1: 'Result-oriented performance',
        averAlgo2: 'advertising algorithm',
        machLearn1: 'Powerful machine learning',
        machLearn2: 'Audience targeting capabilities',
        dataSystem1: 'Customized and regular',
        dataSystem2: 'automated reporting system',
        optimizeTarget1: 'Fully support multiple optimization',
        optimizeTarget2: "objectives 'AARRR'",
        antiCheat: 'Anti-fraud',
        MultiAantiCheating: 'Multi-layer fraud detection',
        MultiAantiCheating1: 'Multi-layer',
        MultiAantiCheating2: 'fraud detection',
        maskAlgo: 'Machine learning realtime blocking algorithms',
        maskAlgo1: 'Machine learning realtime',
        maskAlgo2: 'blocking algorithms',
        toolSystem: '3rd-party Anti-fraud partners',
        toolSystem1: '3rd-party',
        toolSystem2: 'Anti-fraud partners',
        optimizationStrategy: 'Swift and efficient optimization strategy',
        optimizationStrategy1: 'Swift and efficient',
        optimizationStrategy2: 'optimization strategy',
        developerTraffic: 'Direct App Developer Traffic',
        vendorTraffic: 'Premium OEM Traffic',
        sspTraffic: 'SSP Traffic',
        monePlan: 'Customized Monetization Service',
        monePlan1: 'Customized',
        monePlan2: 'Monetization Service',
        serviceTeam: "Professional Monetization Service Team",
        serviceTeam1: "Professional Monetization",
        serviceTeam2: "Service Team",
        MultiAantiCheatContent: 'Support multiple integration methods, API/RTB/S2S',
        monePlanContent: 'Provide customized monetization solutions based on traffic attribute differentiation',
        serviceTeamContent: "Provide '1on1' professional monetization Q&A services",
        emergingMedia: {
            coreAgent: "Emerging Media Core Agency",
            coreAgentContent: "Internal market information including<br>policies/performance updated by media platforms",
            consultService: "Professional Advertising Consulting Services",
            consultServiceContent: "Provide E2E professional services<br>including Campaign Setup/Deposit/UA Audit/Creative",
            mediaSource: "Differentiated Media Resources",
            mediaSourceContent: "All T2/T3 emerging markets covered<br>Offering different categories of Ad slots",
            coveredArea: "Geo:",
            realizeProducts: "Monetization Products:",
            suitableCategory: "Categories:",
            bigo: {
                coveredArea: "Middle East/South America/<br>Southeast Asia/Russia",
                realizeProducts: "IMO/Likee",
                suitableCategory: "Social&Entertainment/Utilities/<br>Gaming/E-commerce",
            },
            kwai: {
                coveredArea: "Indonesia/South America ",
                realizeProducts: "Kwai/Snack Video",
                suitableCategory: "E-commerce/Gaming/Utilities/<br>Social&Entertainment",
            },
            miAds: {
                coveredArea: "Indonesia/India/Russia",
                realizeProducts: "GetApps/MIUI Ecosystem",
                suitableCategory: "Utilities/Social&Entertainment/<br>Fintech/Education/Gaming",
            },
            transsion: {
                coveredArea: "Africa",
                realizeProducts: "Transsion Ecology",
                suitableCategory: "Utilities/Social&Entertainment/<br>Gaming",
            },
            phoenixads: {
                coveredArea: "Africa/Middle East/Latin America",
                realizeProducts: "Phoenix Browser",
                suitableCategory: "E-commerce/Fintech/Utilities/<br>Gaming",
            },
            honor: {
                coveredArea: "MENA/South Africa/EU/MY/<br>LATAM",
                realizeProducts: "APP Store",
                suitableCategory: "Utilities/Social&Entertainment/<br>Fintech/Gaming",
            },
        },
        creativeMaterial: {
            materialType: "Diverse Creative Types",
            materialTypeContent: "Banner/3D/Live action/Playable Ads <br>matching different products and media features",
            materialCreative: "Localized Creative Ideas",
            materialCreativeContent: "Localized professional creative design team <br>providing the most 'authentic' creatives",
            materialOutput: "Batch Creative Output",
            materialOutputContent: "Efficient creative design team <br>helping advertisers achieve maximum ads impression exposure",
            materialService: "Customized Creative Services",
            materialServiceContent: "Highly customized creatives to meet the needs of <br>different advertising display scenarios",
            customerCaseTitle: "Customer Case",
            customerCase: {
                games: {
                    title1: "Gaming",
                    title2: "Project Experience",
                    title3: "Ⅰ.Causal Game<br>Ⅱ.Hyper-Causal Game<br>Ⅲ.HardCore-Game",
                    title4: "Platform&Optimize strategy",
                    title5: '<span style="font-size:0.16rem;font-weight:bold">Region:</span> T1&T3 countries <br><p style="font-size:0.16rem;font-weight:bold;margin-top:0.08rem;display:inline-block">Platform:</p>BIGOAds,KWAI,Google,TikTok,Meta <br><p style="font-size:0.16rem;font-weight:bold;margin:-3px 0;">Optimization direction: </p><br>For Class I and II apps,  video creatives are mainly game recordings, combined with live-action videos, which can display game special effects and user interfaces.For Class III apps, to reach the ROI goal, we rapidly iterate creatives to test out the direction of creatives, and reduce install and event cost.<br><p style="font-size:0.16rem;font-weight:bold;margin-top:0.08rem;display:inline-block">Goal:</p> Reduce CPI cost and increase ad click-through rate and conversion rate.',
                    title6: "Performance",
                    title7: "CTR",
                    title8: "CPI costs were reduced by",
                },
                entertainmentSocial: {
                    title1: "Social",
                    title2: "Project Experience",
                    title3: "1v1 Live chat, Live Stream, Chat Room, Dating",
                    title4: "Platform&Optimize strategy",
                    title5: '<span style="font-size:0.16rem;font-weight:bold">Region:</span> MENA&T1/T2 Countries <br><p style="font-size:0.16rem;font-weight:bold;margin-top:0.08rem;display:inline-block">Platform:</p>BIGOAds,KWAI,Google,TikTok <br><p style="font-size:0.16rem;font-weight:bold;margin:-3px 0;">Optimization direction: </p><br>Broad targeting and A/B test are adopted in the beginning stage and we use the event optimization method later to narrow down the audience. Meanwhile, creatives are designed based on the characteristics and function of the application to meet the KPI of clients.<br><p style="font-size:0.16rem;font-weight:bold;margin-top:0.08rem;display:inline-block">Goal:</p> increase ROAS and user retention rate.',
                    title6: "Performance",
                    title7: "Monthly ROAS",
                    title8: "D1 RR (Retention Rate)",
                },
                entertainment: {
                    title1: "Entertainment",
                    title2: "Project Experience",
                    title3: "Short Video/ Streaming media",
                    title4: "Platform&Optimize strategy",
                    title5: '<span style="font-size:0.16rem;font-weight:bold">Region:</span>T3 countries,<br><p style="font-size:0.16rem;font-weight:bold;margin-top:0.08rem;display:inline-block">Platform:</p>BIGOAds,KWAI,Google,TikTok,Meta <br><p style="font-size:0.16rem;font-weight:bold;margin:-3px 0;">Optimization direction: </p><br> We use APP native user interface and content to make video creatives, mixed with funny, magic effects, beauty, life, and current affairs hotspots. Also, local popular BGM is added to attract users and increase the retention rate.<br><p style="font-size:0.16rem;font-weight:bold;margin-top:0.08rem;display:inline-block">Goal:</p> reduce CPI cost while improving retention rate.',
                    title6: "Performance",
                    title7: "Daily Install volume",
                    title8: "D1 RR (Retention Rate)",
                },
                tools: {
                    title1: "Utilities",
                    title2: "Project Experience",
                    title3: "Ⅰ.Cleaner, Phone Booster<br>Ⅱ.Beauty Cam, Video editor<br>Ⅲ.Fitness, Health Monitor",
                    title4: "Platform&Optimize strategy",
                    title5: '<span style="font-size:0.16rem;font-weight:bold">Region:</span>Worldwide.<br><p style="font-size:0.16rem;font-weight:bold;margin-top:0.08rem;display:inline-block">Platform:</p>BIGOAds,KWAI,Google,TikTok <br> <p style="font-size:0.16rem;font-weight:bold;margin:-3px 0;">Optimization direction: </p><br>With New media core agency certification (such as BigoAds/Kwai/MiAds/Honor, etc.), we can obtain first-hand market information/ ads policy/ performance benchmark updated by media platforms, and give the best marketing strategy based on the characteristics of the application.<br>a. AAA + CBO Ads +Goal-based bidding<br>b. Dynamic creatives +  Spend-based bidding <br><p style="font-size:0.16rem;font-weight:bold;margin-top:0.08rem;display:inline-block">Goal:</p> Reduce UA costs and increase user retention rate.',
                    title6: "Performance",
                    title7: "User Acquisition Costs were reduced by",
                    title8: "D1 RR Increase",
                },
                finance: {
                    title1: "Finance",
                    title2: "Project Experience",
                    title3: "Ⅰ.Loan, Financial Management<br>Ⅱ.Binary options&Investment Products",
                    title4: "Platform&Optimize strategy",
                    title5: '<span style="font-size:0.16rem;font-weight:bold">Region:</span>Worldwide <br><p style="font-size:0.16rem;font-weight:bold;margin-top:0.08rem;display:inline-block">Platform:</p>BIGOAds,KWAI,Google,TikTok <br><p style="font-size:0.16rem;font-weight:bold;margin:-3px 0;">Optimization direction: </p><br>We have successfully helped several companies reach the top-tier ranking of Google Play since 2019. Based on our rich vertical experience, we can adjust creatives according to media platform restrictions to increase brand compliance.The advertising review system of emerging media platforms (such as BIGOads and Kwai) is more flexible than traditional media. Under the current circumstances, they are becoming the best alternative for financial advertisers.<br><p style="font-size:0.16rem;font-weight:bold;margin-top:0.08rem;display:inline-block">Goal:</p> Reduce user account activation costs and increase daily new registration',
                    title6: "Performance",
                    title7: "lower CPA",
                    title8: "New user acquisition increased",
                }


            }


        },
        mediaInvestment: {
            growthStrategy: "Launch & Growth Consultation Service",
            growthStrategyContent: "Develop the best user growth marketing strategy based on in-house proprietary databases",
            advertisingService: "Paid Advertising Services",
            advertisingServiceContent: "30+ design/optimization experts providing 1 on 1 professional and timely advertising services",
            mediaResources: "Multiple Categories & Platforms Coverage",
            mediaResourcesContent: "Global mainstream media coverage to meet the different optimization needs of advertisers",
            digitalMarketing: "AI-based Digital Marketing Platform",
            digitalMarketingContent: "Self-developed AI-based marketing platform to achieve efficient and accurate UA",
            carouselTitle1: "Social & Searching",
            carouselTitle2: "OEM Partners",
            carouselTitle3: "Emerging Media",
            carouselTitle4: "Other Partners",
        }
    },
    contact: {
        title1: 'Get In Touch With Novabeyond',
        title2: "We'd love to hear from you."
    }
}