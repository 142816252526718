export default {
    // 导航
    navigator:{
        menu:[
            {title:'解决方案',children:[],index:'/solution'},
            {title:'最新动态',children:[],index:'/news-room'},
            {title:'关于我们',children:[],index:'/about-us'},
            {title:'联系我们',children:[],index:'/contact'},
        ]
    },
    home:{
        carouseTop:{
            bgText1:'Make Life Simple',
            bgText2:'Through Advertising',
            bgText3:'Global Mobile Performance',
            bgText4:'Advertisting Experts',
            bgText5:'Gateway To',
            bgText6:'New Emerging Markets',
        },
        carouseMid:[
            {
                title:'广告平台',
                index:'0',
                title1:'自有程序化广告平台',
                title2:'直接对接全球厂商/开发者资源',
            },
            {
                title:'媒体代投',
                index:'1',
                title1:'多品类多平台投放经验',
                title2:'提供以效果为导向的专业优化服务',
            },
            {
                title:'创意素材',
                index:'2',
                title1:'丰富的素材创作经验',
                title2:'年轻的设计团队，专业创新的广告创意',
            },
            {
                title:'新兴媒体',
                index:'3',
                title1:'新兴媒体核心代理资质',
                title2:'协助客户完成出海深耕',
            },
            {
                title:'变现平台',
                index:'4',
                title1:'基于移动广告技术以及智能优化算法',
                title2:'提供一站式广告变现服务',
            },
        ],
        solutionTitle:'解决方案',
        customerTitle:'客户合作伙伴',
        mediaTitle:'媒体资源'
    },
    footer:{
        solutionTitle:'解决方案',
        platName:'程序化广告平台',
        mediaAgency:'媒体代投',
        creativeMaterial:'创意素材',
        emergingMedia:'新兴媒体',
        realizationPlatform:'变现平台',
        latestNews:'最新动态',
        aboutUs:'关于我们',
        aboutNova:'关于Novabeyond',
        joinUs:'招贤纳士',
        privacyPolicy:"隐私政策",
        concate:'联系我们',
    },
    news:{
        bgTitle:'Blog & Event',
        event:"活动",
        blog:"博客",
        blogOfBigo:'NOVABEYOND获得BigoAds核心代理资质',
        blogOfBigoIntro:"2021年6月11日，NOVABEYOND正式成为BigoAds出海的一级代理商，证明了我们的行业影响力、综合推广营销能力、以及综合业务能力得到BIGO的官方认可。",
        blogOfKwai:"NOVABEYOND获得快手核心代理资质",
        blogOfKwaiIntro:"2021年12月31日，NOVABEYOND收到了来自快手出海广告的合作伙伴授权书，成为国内出海者开拓新市场的强大助力。",
        bigoAdsParagraph1: "NOVABEYOND获得BIGOAds核心代理资质",
        bigoAdsParagraph2:"2021年6月11日，Novabeyond正式成为BIGOAds出海的一级代理商，证明了我们的行业影响力、综合推广营销能力、以及综合业务能力得到了BIGO的官方认可。",
        bigoAdsParagraph3:"关于BIGO Ads",
        bigoAdsParagraph4:"BIGO Ads是BIGO 公司为全球合作伙伴提供的移动互联网广告业务营销平台。聚合了短视频产品Likee和通讯社交产品imo的优质流量，重点深耕中东、南亚、东南亚、俄罗斯等市场蓝海，以海量的用户数据和高质的内容生态为支撑，创意组合多元化广告玩法，致力于为全球合作伙伴提供一站式的商业化营销解决方案。",
        kwaiBusinessParagraph1:"NOVABEYOND获得快手核心代理资质",
        kwaiBusinessParagraph2:"Novabeyond 最近宣布与 Kwai for Business 作为官方营销合作伙伴建立全球广告合作伙伴关系。作为官方营销合作伙伴，Novabeyond将为全球广告商提供活动管理和移动增长机会。",
        kwaiBusinessParagraph3:"关于Kwai",
        kwaiBusinessParagraph4:"快手旗下产品拥有超过10亿的月活跃用户，包括短视频平台快手和Snack Video小视频平台。 随着海外短视频和直播行业的不断兴起，Kwai一直紧抓全球新兴市场机遇，利用国内成熟的运营经验和服务体系，结合海外本地化市场，为广告主提供多形式的营销服务。Kwai for Business为营销人员提供了庞大、优质的目标受众和强大的消费能力。快手目前在部分品类和地区的产品表现已经优于传统媒体。",
        miAds:{
            title:"NOVABEYOND成为MiAds核心代理",
            titleInfo:'2022年6月，NOVABEYOND和小米国际互联网广告平台(MiAds)共同宣布双方正式达成合作伙伴关系，NOVABEYOND成为MiAds的核心代理！这一官宣充分表示了MiAds对NOVABEYOND实力的认可。',
            paragraph1:"NOVABEYOND成为MiAds核心代理",
            paragraph2:"小米公司正式成立于2010年4月，是一家以智能手机、智能硬件和 IoT 平台为核心的消费电子及智能制造公司。截止2018年，小米的业务遍及全球80多个国家和地区。目前，小米是<b>全球第四大智能手机制造商</b>，在30余个国家和地区的手机市场进入了前五名，特别是在印度，连续5个季度保持手机出货量第一。",
            paragraph3:"<b>NOVABEYOND</b>自成立以来，就一直秉持着为客户服务的初心，致力于为客户提供最优质的推广服务。我们拥有经验丰富的效果类投放团队以及专业创新的素材设计团队，为客户提供定制化的流量解决方案。目前，我们已服务超过200+全球各行业广告主，日均可达10亿次广告曝光 。今后，<b>NOVABEYOND</b>将携手<b>小米国际互联网广告平台（MiAds）</b>协助为国内出海者完成海外市场的开拓，共同构建出海蓝图！",
            paragraph4:"【多样化广告版位最大化曝光并提升转化效果：Native ads/Icon/Rewards video/Banner/Pop-up ads】",
            paragraph5:'2022年6月，<b>NOVABEYOND</b>和<b>小米国际互联网广告平台(MiAds)</b>共同宣布双方正式达成合作伙伴关系，NOVABEYOND成为MiAds的核心代理！这一官宣充分表示了MiAds对NOVABEYOND实力的认可。',
        },
        shortVideo:{
            title:"NOVABEYOND成为国内短视频出海流量一级代理",
            titleInfo:"近年来，NOVABEYOND不断突破自我，不断寻求新机遇 ，先后获得BIGO Ads和Kwai For Business的核心代理资质，成为国内短视频出海流量一级代理！",
            paragraph1:"<b>NOVABEYOND</b>于2018年正式成立，海外营销业务正式启动。自此，NOVABEYOND不断突破自我，不断寻求新机遇。近年来，NOVABEYOND先后获得<b>BIGO Ads</b>和<b>Kwai For Business</b>的核心代理资质，成为<b>国内短视频出海流量一级代理！</b>",
            paragraph2:"<b>BIGO Ads</b>是BIGO面向全球市场的移动互联网广告营销平台，2019年Q4开始进行品牌广告的商业化变现，2020年6月上线DSP平台，为广告主提供效果广告投放服务。流量主要集中在<b>中东、南亚、东南亚和俄罗斯</b>这些蓝海市场，旗下拥有两款王牌应用：<b>短视频软件Likee</b>（MAU-1.5亿+）<b>&社交软件imo</b>（MAU-2亿+）。",
            paragraph3:"【广告版位：Likee、imo；多种广告样式，最大化触达用户】",
            paragraph4:"近年来，<b>快手</b>在海外市场增势强劲，<b>Kwai</b>作为快手的“国际版”在东南亚、俄罗斯、南美等地区人气爆棚，在不断加速国际化的同时，也在进行海外产品商业化的尝试，终于正式在巴西上线品牌化广告平台<b>“Kwai for Business”</b>，不仅吸引大量海外本地品牌关注与投放，也成为中国本土品牌出海的全新流量红利渠道，满足不同领域品牌的增强曝光、拓展新用户、提升效果转化等多元营销需求。",
            paragraph5:"【投放平台：Kwai、SnackVideo(适用于电商、游戏、社交等多种品类的信息流广告投放)】",
            paragraph6:"<b>NOVABEYOND、BIGO Ads、Kwai For Business</b>三方强强联合，为全球广告主打造定制化流量解决方案，提升广告主品牌价值，助力广大出海者乘风破浪，一路前行！",
        },
        shareChat:{
            title:"Novabeyond成为Sharechat平台在中国大陆境内唯一一家核心代理",
            titleInfo:"Novabeyond 于2022年正式成为Sharechat平台在中国大陆境内唯一一家核心代理。此后，Novabeyond将携手Sharechat为全球广告主提供更加精准有效的推广方案，协助全球开发者完成印度地区的出海布局。",
            paragraph1:"Novabeyond正式成为Sharechat平台核心代理",
            paragraph2:"<b>Novabeyond</b>于2022年正式成为<b>Sharechat平台</b>在中国大陆境内唯一一家核心代理。此后，Novabeyond将携手Sharechat为全球广告主提供更加精准有效的推广方案，协助全球开发者完成印度地区的出海布局。",
            paragraph3:"<b>Sharechat</b>成立于2015年，旨在为印度本地用户提供新的社交互动方式。经过多年的发展，ShareChat不断提升其内容多样性，目前ShareChat已成为<b>印度最大的社交媒体</b>。与此同时，Sharechat启动了以广告为主的商业化模式，主打印度本地流量平台，目前已成为在印度地区除Facebook、Google之外的<b>第三大数字广告营销系统</b>，旗下的短视频APP：<b>Sharechat</b>和<b>Moj</b>，每日用户下载人数及月活跃用户量在App Annie印度区榜单名列前茅。",
            paragraph4:"【Display，Video等广告版位可进行最大化产品曝光】",
            paragraph5:"自<b>NOVABEYOND</b>成立以来，就一直秉持着为客户服务的初心，致力于为客户提供最优质的推广服务，作为<b>Sharechat</b>平台在中国大陆境内的核心代理，将依托媒体平台的独特优势以及自身成熟的团队构成，助力全球广告主进行海外市场的拓展！",
        }
    },
    aboutUs:{
        timeLine2018:"2018年--Novabeyond正式成立，海外营销业务正式启动；",
        timeLine2019:"2019年--印度Office正式成立；成功获得GoogleAds的一级代理资质；成功和全球160+公司建立合作；",
        timeLine2020:"2020年--印尼Office正式成立；",
        timeLine2021:"2021年--成功获得Bigoads的核心代理资质",
        timeLine2022:"2022年--成功获得快手和小米的核心代理资质；非洲office正式成立。",

    },
    solution:{
        programAdverPlat:'程序化广告平台',
        trafficPartner:"流量合作伙伴",
        averAlgo:'以效果为导向的广告算法系统',
        machLearn:'强大的机器学习与精准定位功能',
        dataSystem:'自动化/定制化报表数据系统',
        optimizeTarget:'全面支持AARRR多种优化目标',       
        antiCheat:'反作弊机制',
        MultiAantiCheating:'多维度反作弊检测',
        MultiAantiCheating1:'多维度',
        MultiAantiCheating2:'反作弊检测',
        maskAlgo:'实时假量屏蔽算法',
        maskAlgo1:'实时假量',
        maskAlgo2:'屏蔽算法',
        toolSystem:'第三方反作弊工具系统',
        toolSystem1:'第三方',
        toolSystem2:'反作弊工具系统',
        optimizationStrategy:'快速精准优化策略',
        optimizationStrategy1:'快速精准',
        optimizationStrategy2:'优化策略',
        developerTraffic:'开发者流量',
        vendorTraffic:'优质厂商流量',
        sspTraffic:'SSP流量',
        monePlan:'定制化变现方案',
        monePlan1:'定制化',
        monePlan2:'变现方案',
        serviceTeam:"专业化变现服务团队",
        serviceTeam1:"专业化",
        serviceTeam2:"变现服务团队",
        MultiAantiCheatContent:'支持API/RTB/S2S多种对接方式',
        monePlanContent:'针对流量属性，提供定制化变现方案',
        serviceTeamContent:'提供1on1专业变现服务',
        emergingMedia:{
            coreAgent: "新兴媒体核心代理",
            coreAgentContent:"全面覆盖Tier2/Tier3发展中国家流量",
            consultService:"专业广告推广咨询服务",
            consultServiceContent:"提供账户搭建/充值/投放/素材E2E专业服务",
            mediaSource:"差异化媒体资源",
            mediaSourceContent:"提供短视频/应用商店/浏览器等不同类型媒体资源",
            coveredArea:"覆盖地区:",
            realizeProducts:"变现产品:",
            suitableCategory:"适合品类:",
            bigo:{
                coveredArea:"南亚、东南亚、中东、俄罗斯",
                realizeProducts:"IMO/Likee",
                suitableCategory:"工具、泛娱乐社交、游戏、电商",
            },
            kwai:{
                coveredArea:"印尼、南美",
                realizeProducts:"Kwai/Snack Video",
                suitableCategory:"工具、泛娱乐社交、游戏、电商",
            },
            miAds:{
                coveredArea:"印度、印尼、俄罗斯",
                realizeProducts:"应用商店、GetApps",
                suitableCategory:"工具、泛娱乐社交、金融、教育、<br>游戏",
            },
            transsion:{
                coveredArea:"非洲",
                realizeProducts:"应用商店",
                suitableCategory:"工具、泛娱乐社交、游戏",
            },
            phoenixads:{
                coveredArea:"非洲、中东、拉美",
                realizeProducts:"Phoenix Browser",
                suitableCategory:"工具、金融、游戏、电商",
            },
            honor:{
                coveredArea:"中东、南非、欧洲、马来西亚、<br>拉美",
                realizeProducts:"应用商店",
                suitableCategory:"工具、泛娱乐社交、游戏、金融",
            },

        },
        creativeMaterial:{
            materialType:"多样化素材类型",
            materialTypeContent:"平面/3D/真人实拍/试玩广告<br>匹配不同产品及媒体特点",
            materialCreative:"本地化素材创意",
            materialCreativeContent:'本地化专业素材团队<br>提供“原汁原味”素材内容',
            materialOutput:"批量化素材产出",
            materialOutputContent:"高效素材制作团队<br>协助广告主实现产品最大曝光",
            materialService:"定制化素材服务",
            materialServiceContent:"定制化素材服务<br>满足广告主不同广告展示场景需求",
            customerCaseTitle:"客户案例",
            customerCase:{
                games:{
                    title1:"游戏类",
                    title2:"过往合作经验",
                    title3:"Ⅰ.休闲游戏<br>Ⅱ.超休闲游戏<br>Ⅲ.中重度游戏",
                    title4:"平台及策略",
                    title5:"对于Ⅰ、Ⅱ类产品，视频素材以游戏实录为主，搭配真人模拟，展示游戏特效及操作界面，引导下载并提升用户留存。<br>对于Ⅲ类产品，投放T1&T3国家为主，多以Facebook平台为主，搭配强大的素材设计师团队，快速迭代素材测试出方向，降低买量价格，实现高付费转化。",
                    title6:"数据表现",
                    title7:"点击率（高于平均水平)",
                    title8:"优质素材CTR",
                },
                entertainmentSocial:{
                    title1:"泛娱乐社交",
                    title2:"过往合作经验",
                    title3:"1v1社交、直播、语聊房、社交+游戏产品、Dating类产品",
                    title4:"平台及策略",
                    title5:"优化手段采用前期通投，且Google中后期2.5事件表现效果最好。由于受众用户多为男性<br>，素材则尽可能多地迭代各类端内玩法、美女直播等素材，并对<br>不同的人群进行定向测试。",
                    title6:"数据表现",
                    title7:"1v1直播-月ROAS",
                    title8:"语聊类-次留",
                },
                entertainment:{
                    title1:"娱乐类",
                    title2:"过往合作经验",
                    title3:"短视频、流媒体为主",
                    title4:"平台及策略",
                    title5:"T3国家为主，投放平台侧重Google&Facebook，素材方向以搞笑、特效、美女、当地热点及热门BGM类型视频为主，添加产品UI及特效，尽可能多地展现端内内容。从而达到以较低的CPI获客的同时提升留存。",
                    title6:"数据表现",
                    title7:"平均日安装量级",
                    title8:"平均次留",
                },
                tools:{
                    title1:"工具类",
                    title2:"过往合作经验",
                    title3:"Ⅰ.清理产品、本地加速器<br>Ⅱ.相机、视频剪辑软件<br>Ⅲ.健身健美、健康类产品",
                    title4:"平台及策略",
                    title5:"对于Ⅰ类产品，受众用户普遍集中于T3国家，我司则拥有多个T3国家垂直流量媒体平台的核代资质，如：BigoAds/Kwai/MiAds/Honor等；同时传统大媒体平台的采用投放方式一般采用：a.AAA+普通CBO广告+竞价上限 b.程序化创意+普通竞价广告。对于Ⅱ、Ⅲ类产品，多投放于Facebook&Google媒体平台，地区基本分布于T1国家，结合不同产品端内功能，搭配素材和出价策略，实现KPI增长。",
                    title6:"数据表现",
                    title7:"获客成本（低于预期）",
                    title8:"次留高于平均值",
                },
                finance:{
                    title1:"金融类（现金贷）",
                    title2:"过往合作经验",
                    title3:"Ⅰ.现金贷、贷超<br>Ⅱ.二元期权等产品",
                    title4:"平台及策略",
                    title5:"对于Ⅰ类产品，多投放于Google&Tiktok平台，以ID/NG/MX及其他SEA地区为主，我司优化团队拥有长期投放此品类的独到出价经验，搭配强大的本地化团队支持，以及当地素材的输出，自2019年起已助力多家此品类在不同国家和地区位于榜单前茅。<br>对于Ⅱ类产品，多采用Facebook投放，帮助其在T1&T2国家以较低的注册成本实现高额的入金转化；同时考虑其他产品的牌照限制，我司作为核代的BigoAds媒体平台也拥有异于传统大媒体的优势政策，可协助广告主实现全球效果广告的推广。",
                    title6:"数据表现",
                    title7:"申请成本低于（市场大盘)",
                    title8:"日新增安装",
                }
            }
        },
        mediaInvestment:{
            growthStrategy:"用户增长策略",
            growthStrategyContent:"基于历史投放数据，指定最佳用户增长营销策略",
            advertisingService:"广告投放服务",
            advertisingServiceContent:"30+设计/优化团队，提供1对1最专业及时的广告投放服务",
            mediaResources:"全媒体资源服务",
            mediaResourcesContent:"覆盖全球主流媒体，满足广告主不同投放需求",
            digitalMarketing:"自动化数字营销平台",
            digitalMarketingContent:"自研智能营销平台，实现高效精准获客",
            carouselTitle1:"社交&搜索媒体",
            carouselTitle2:"厂商流量",
            carouselTitle3:"新兴媒体",
            carouselTitle4:"其他",
        }
    },
    contact:{
        title1:'联系我们',
        title2:"我们将及时响应您的问题"
    }
}