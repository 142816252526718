<template>
    <div class="footer" v-lazy:background-image="require('../assets/images/bg-footer.jpg')">
        <div class="img-left" @click="changeIndex('/')">
            <router-link to="/">
                <img src="../assets/images/logo-footer.png" alt="" style="width: 1.7rem;height:1.05rem">
            </router-link>
        </div>
        <div class="footer-flex">
            <div class="footer-title">
                <div :class="[$i18n.locale=='en'?'little-footer-en':'little-footer-zh','footer-nav-title']">{{ $t("footer.solutionTitle") }}</div>
                <router-link to="/programmatic">
                    <p @click="changeIndex('programmatic')">{{ $t("footer.platName") }}</p>
                </router-link>
                <router-link to="/ua-audit">
                    <p @click="changeIndex('ua-audit')">{{ $t("footer.mediaAgency") }}</p>
                </router-link>

                <router-link to="/creative-lab">
                    <p @click="changeIndex('creative-lab')">{{ $t("footer.creativeMaterial") }}</p>
                </router-link>

                <router-link to="/new-media">
                    <p @click="changeIndex('new-media')">{{ $t("footer.emergingMedia") }}</p>
                </router-link>
                <router-link to="/monetization">
                    <p @click="changeIndex('monetization')">{{ $t("footer.realizationPlatform") }}</p>
                </router-link>
            </div>
            <div class="footer-title">
                <router-link to="/news-room">
                    <div :class="[$i18n.locale=='en'?'little-footer-en':'little-footer-zh','footer-nav-title']" @click="changeIndex('news-room')">
                        {{ $t("footer.latestNews") }}
                    </div>
                </router-link>
            </div>
            <div class="footer-title">
                <div :class="[$i18n.locale=='en'?'little-footer-en':'little-footer-zh','footer-nav-title']">{{ $t("footer.aboutUs") }}</div>
                <router-link to="/about-us">
                    <p @click="changeIndex('about-us')">{{ $t("footer.aboutNova") }}</p>
                </router-link>
                <router-link to="/join-us">
                    <p @click="changeIndex('join-us')">{{ $t("footer.joinUs") }}</p>
                </router-link>
                <router-link to="/privacy-policy">
                    <p @click="changeIndex('privacy-policy')">{{ $t("footer.privacyPolicy") }}</p>
                </router-link>
            </div>
            <div class="footer-title">
                <router-link to="/contact">
                    <div :class="[$i18n.locale=='en'?'little-footer-en':'little-footer-zh','footer-nav-title']" @click="changeIndex('contact')">{{ $t("footer.concate") }}</div>
                </router-link>
                <p>sales@novabeyond.com</p>
                <p>monetization@novabeyond.com</p>
                <p>
                    <a href="https://www.facebook.com/Novabeyond-105988492114632/">
                        <img src="../assets/images/fb.png" alt="" class="footer-img-style">
                    </a>
                    <a href="https://www.linkedin.com/company/novabeyond/mycompany/?viewAsMember=true">
                        <img src="../assets/images/linkedin.png" alt="" style="margin-left: 10px;"
                            class="footer-img-style">
                    </a>
                    <!-- <a href="https://join.skype.com/invite/u6wIvcjBjRdG">
                         <img src="../assets/images/skype.png" alt="" style="margin-left: 10px;width: 23px;height: 23px;">
                    </a>                   -->
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavFooter',
    methods: {
        changeIndex(data) {
            this.$emit('footer', data)
        }
    }
}
</script>
<style lang="scss" scoped>
.footer {
    width: 100%;
    // height: 4.83rem;
    background-size: cover;
    color: #fff;
    font-family: PingFang SC;
    display: flex;
    align-items: center;
    min-height: 120px;
    padding: 1rem 0;

    .img-left {
        margin-left: 3.6rem;
    }

    .footer-flex {
        display: flex;
        margin-left: 1.88rem;
    }

    .footer-nav-title {
        font-size: 0.16rem;
        font-weight: 400;
        margin-right: 1.6rem;
    }

    .footer-title {
        a {
            color: #fff;
            text-decoration: none !important;
        }

        font-size: 0.08rem;
        opacity: 0.8;

        .footer-img-style {
            width: 23px;
            height: 23px;
        }
    }
}

//移动端
@media screen and (max-width: 650px) {
    .footer {
        .img-left {
            margin-left: 0.5rem
        }

        .footer-flex {
            margin-left: 0.2rem;
        }
    }

}

@media screen and (max-width:500px) {
    .footer {
        .footer-nav-title.little-footer-en {
            font-size: 0.3rem;
            margin-right: 0.5rem;
        }
          .footer-nav-title.little-footer-zh {
            font-size: 0.3rem;
            margin-right: 0.9rem;
        }

        .footer-title {
            p {
                margin: 0.1rem 0;
                font-size: 0.2rem;
            }
            .footer-img-style {
                width: 16px;
                height: 16px;
            }

        }

    }

}
</style>