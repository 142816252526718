<template>
  <div id="app">
    <nav-header :activeIndex="headerIndex"></nav-header>
     <!-- <router-view></router-view> -->
    <keep-alive>
      <router-view></router-view>
    </keep-alive>

    <nav-footer @footer="messageFooter($event)"></nav-footer>

  </div>
</template>
<script>
import NavHeader from '@/components/NavHeader'
import NavFooter from '@/components/NavFooter'
export default {
  components: {
    NavHeader,
    NavFooter,
  },
  data() {
    return {
      headerIndex: '',
    }
  },
  methods: {
    messageFooter(index) {
      this.headerIndex = index;
    },
  },
  watch:{
    $route:{
      handler: function(newValue, oldValue){
        if(newValue.path==='/'){
          this.headerIndex = "/"
        }
      }
    }
  }

}
</script>

<style lang="scss">
#app {
  font-family: "PingFangSC-Regular, sans-seri";
  margin: 0;
  padding: 0;
}
</style>
