<template>
<div>
  <div class="nav-header">
    <el-menu mode="horizontal" :default-active="activeIndex" router background-color="rgba(0,0,0,0.4)" text-color="#fff"
      active-text-color="#5584a8">
      <el-menu-item index="/" :class="[$i18n.locale == 'zh' ? 'header-nav-zh' : 'header-nav-en']">
        <img src="../assets/images/logo.png" alt="" style="width: 1.7rem;min-width: 55px;">
      </el-menu-item>
      <el-submenu index="solution">
        <template slot="title">{{ $t('footer.solutionTitle') }}</template>
        <el-menu-item index="programmatic">{{ $t('footer.platName') }}</el-menu-item>
        <el-menu-item index="ua-audit">{{ $t('footer.mediaAgency') }}</el-menu-item>
        <el-menu-item index="creative-lab">{{ $t('footer.creativeMaterial') }}</el-menu-item>
        <el-menu-item index="new-media">{{ $t('footer.emergingMedia') }}</el-menu-item>
        <el-menu-item index="monetization">{{ $t('footer.realizationPlatform') }}</el-menu-item>
      </el-submenu>
      <el-menu-item index="news-room">{{ $t('footer.latestNews') }}</el-menu-item>
      <el-submenu index="aboutus">
        <template slot="title">{{ $t('footer.aboutUs') }}</template>
        <el-menu-item index="about-us">{{ $t('footer.aboutNova') }}</el-menu-item>
        <el-menu-item index="join-us">{{ $t('footer.joinUs') }}</el-menu-item>
        <el-menu-item index="privacy-policy">{{ $t('footer.privacyPolicy') }}</el-menu-item>
      </el-submenu>
      <el-menu-item index="contact">{{ $t('footer.concate') }}</el-menu-item>
      <el-menu-item v-if="$i18n.locale === 'zh'" @click="changeLanguage">中/英</el-menu-item>
      <el-menu-item v-else @click="changeLanguage">EN/CN</el-menu-item>
    </el-menu>
  </div>

</div>
  
</template>

<script>

export default {
  name: 'NavHeader',
  props: ['activeIndex'],
  methods: {
    changeLanguage() {
      this.$i18n.locale = this.$i18n.locale === 'en' ? 'zh' : 'en';
      sessionStorage.setItem('language', this.$i18n.locale)
      document.title = this.$i18n.locale === 'en' ? 'Novabeyond - Mobile Marketing Expert | Gateway to Emerging Market': "Novabeyond - 移动流量营销专家 | 新兴市场流量入口";
      this.$forceUpdate();
    },
  },
  watch: {
    activeIndex(newValue, oldValue) {
      this.activeIndex = newValue;
    }
  }
}
</script>
<style lang='scss' scoped>
  .el-menu--horizontal>.el-menu-item:not(:first-child){
  margin: 0 0.2rem 0 0.3rem;
} 
.nav-header {
  display: flex;
  position: absolute;
  z-index: 10;
  top: 0.28rem;

  .el-menu--horizontal>.el-menu-item.header-nav-zh,
  .el-menu--horizontal>.el-menu-item.header-nav-en {
    margin-left: 3.62rem;
    margin-right: 3rem;
    margin-top: -5px;
  }
}

@media screen and (max-width:890px) {
  .nav-header .el-menu--horizontal>.el-menu-item.header-nav-en {
    margin-left: 1rem;
    margin-right: 0rem;
  }
}
// 移动端 
@media screen and (max-width:600px) {
  .nav-header .el-menu--horizontal>.el-menu-item.header-nav-zh,
  .nav-header .el-menu--horizontal>.el-menu-item.header-nav-en {
    margin-left: 0.3rem;
    margin-right: 0.5rem;
    margin-top: -3px;
  }
}
@media screen and (max-width:520px) {
  .el-menu--horizontal>.el-menu-item:not(:first-child){
    margin: 0 0.03rem;
  }
}
</style>
