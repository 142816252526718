<template>
  <div class="home-view">
    <!-- 1 -->
    <div>
      <template>
        <el-carousel :interval="5000" arrow="never" ref="cardShow">
          <el-carousel-item>
            <div class="carousel-item1" v-lazy:background-image='carouselBg4'>
              <div>
                <div class="arrow-style">
                  <img src="@/assets/images/right-arrow.png" @click="arrowClick('left')"
                    style="margin-right: 0.2rem;" />
                  <img src="@/assets/images/left-arrow.png" @click="arrowClick('right')" />
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="carousel-item1" v-lazy:background-image="carouselBg1">
              <div>
                <p>{{ $t('home.carouseTop.bgText1') }}</p>
                <p v-html="$t('home.carouseTop.bgText2')"></p>
                <div style="font-size: 0.3rem;display: inline-block;">
                  <div style="display: flex;">
                    <div style="width: 1.18rem;border-bottom: 1px solid #fff;height: 1px;"></div>
                    <div style="margin-left: 0.2rem;margin-right: 0.2rem;margin-top: -0.2rem;">
                      <span style="margin-left: 0.1rem;">广告让生活更加简单</span><br>
                    </div>
                    <div style="width: 1.18rem;border-bottom: 1px solid #fff;height: 1px;"></div>
                  </div>
                </div>
                <div class="arrow-style">
                  <img src="@/assets/images/right-arrow.png" @click="arrowClick('left')"
                    style="margin-right: 0.2rem;" />
                  <img src="@/assets/images/left-arrow.png" @click="arrowClick('right')" />
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="carousel-item2" v-lazy:background-image='carouselBg2'>
              <div>
                <p>{{ $t('home.carouseTop.bgText3') }}</p>
                <p v-html="$t('home.carouseTop.bgText4')"></p>
                <div style="font-size: 0.3rem;display: inline-block;">
                  <div style="display: flex;">
                    <div style="width: 1.18rem;border-bottom: 1px solid #fff;height: 1px;"></div>
                    <div style="margin-left: 0.2rem;margin-right: 0.2rem;margin-top: -0.2rem;">
                      <span style="margin-left: 0.1rem;">海外移动效果营销专家</span><br>
                    </div>
                    <div style="width: 1.18rem;border-bottom: 1px solid #fff;height: 1px;"></div>
                  </div>
                </div>
                <div class="arrow-style">
                  <img src="@/assets/images/right-arrow.png" @click="arrowClick('left')"
                    style="margin-right: 0.2rem;" />
                  <img src="@/assets/images/left-arrow.png" @click="arrowClick('right')" />
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="carousel-item3" v-lazy:background-image='carouselBg3'>
              <div>
                <p>{{ $t('home.carouseTop.bgText5') }}</p>
                <p v-html="$t('home.carouseTop.bgText6')"></p>
                <div style="font-size: 0.3rem;display: inline-block;">
                  <div style="display: flex;">
                    <div style="width: 1.18rem;border-bottom: 1px solid #fff;height: 1px;"></div>
                    <div style="margin-left: 0.2rem;margin-right: 0.2rem;margin-top: -0.2rem;">
                      <span style="margin-left: 0.1rem;">新兴市场流量入口</span><br>
                    </div>
                    <div style="width: 1.18rem;border-bottom: 1px solid #fff;height: 1px;"></div>
                  </div>
                </div>
                <div class="arrow-style">
                  <img src="@/assets/images/right-arrow.png" @click="arrowClick('left')"
                    style="margin-right: 0.2rem;" />
                  <img src="@/assets/images/left-arrow.png" @click="arrowClick('right')" />
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </template>
    </div>
    <!-- 解决方案 -->
    <p class="second-title-24" style="font-size: 0.24rem;font-weight: 600;">{{ $t('home.solutionTitle') }}</p>
    <div class="under-line-blue" :style="{ 'width': $i18n.locale === 'zh' ? '1rem' : '1.5rem' }"></div>
    <div :style="{ 'background-image': 'url(' + imageSrc + ')' }" class="bg-style">
      <div class="title-style" v-if="activeIndex === '0'">
        <div>
          <p>{{ $t('home.carouseMid[0].title1') }}</p>
          <p>{{ $t('home.carouseMid[0].title2') }}</p>
        </div>
      </div>
      <div class="title-style" v-if="activeIndex === '1'">
        <div>
          <p>{{ $t('home.carouseMid[1].title1') }}</p>
          <p>{{ $t('home.carouseMid[1].title2') }}</p>
        </div>
      </div>
      <div class="title-style" v-if="activeIndex === '2'">
        <div>
          <p>{{ $t('home.carouseMid[2].title1') }}</p>
          <p>{{ $t('home.carouseMid[2].title2') }}</p>
        </div>
      </div>
      <div class="title-style" v-if="activeIndex === '3'">
        <div>
          <p>{{ $t('home.carouseMid[3].title1') }}</p>
          <p>{{ $t('home.carouseMid[3].title2') }}</p>
        </div>
      </div>
      <div class="title-style" v-if="activeIndex === '4'">
        <div>
          <p>{{ $t('home.carouseMid[4].title1') }}</p>
          <p>{{ $t('home.carouseMid[4].title2') }}</p>
        </div>
      </div>
      <div :class="[$i18n.locale == 'zh' ? 'solution-zh' : 'solution-en']">
        <el-menu :default-active="activeIndex" mode="horizontal" @select="handleSelect">
          <el-menu-item index="0">{{ $t('home.carouseMid[0].title') }}</el-menu-item>
          <el-menu-item index="1">{{ $t('home.carouseMid[1].title') }}</el-menu-item>
          <el-menu-item index="2">{{ $t('home.carouseMid[2].title') }}</el-menu-item>
          <el-menu-item index="3">{{ $t('home.carouseMid[3].title') }}</el-menu-item>
          <el-menu-item index="4">{{ $t('home.carouseMid[4].title') }}</el-menu-item>
        </el-menu>
      </div>
    </div>
    <!-- 客户合作伙伴 -->
    <div class="customer-company">
      <p class="second-title-24" style="font-size: 0.24rem;font-weight: 600;">{{ $t('home.customerTitle') }}</p>
      <div class="under-line-blue" :style="{ 'width': $i18n.locale === 'zh' ? '1.45rem' : '1.3rem' }"></div>
      <div style="display: flex;justify-content: center;margin-top: 0.5rem;">
        <img v-lazy="customerCopany" :key="customerCopany">
      </div>
    </div>

    <!-- 媒体资源 -->
    <div class="media-resource">
      <div>
        <div style="padding-top: 0.5rem;">
          <p class="second-title-24">{{ $t('home.mediaTitle') }}</p>
          <div class="under-line-white" :style="{ 'width': $i18n.locale === 'zh' ? '1rem' : '1.5rem' }"></div>
          <div style="margin-top: 0.5rem;display: flex;justify-content: center;">
            <img v-lazy="meadiaSource" :key="meadiaSource">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import image4 from '@/assets/images/home-2-1.jpg'
import image5 from '@/assets/images/home-2-2.jpg'
import image6 from '@/assets/images/home-2-3.jpg'
import image7 from '@/assets/images/home-2-4.jpg'
import image8 from '@/assets/images/home-2-5.jpg'
import carouselBg1 from "@/assets/images/home-1-1.jpg"
import carouselBg2 from "@/assets/images/home-1-2.jpg"
import carouselBg3 from "@/assets/images/home-1-3.jpg"
import carouselBg4 from "@/assets/images/home-1-4.jpg"
import customerCopany from '@/assets/images/customer-company.png'
import meadiaSource from '@/assets/images/media-source-img.png'


export default {
  data() {
    return {
      imageSrc: image4,
      title1: '',
      title2: '',
      activeIndex: '0',
      image: [image4, image5, image6, image7, image8],
      carouselBg1: carouselBg1,
      carouselBg2: carouselBg2,
      carouselBg3: carouselBg3,
      carouselBg4: carouselBg4,
      customerCopany: customerCopany,
      meadiaSource: meadiaSource,
    }
  },
  methods: {
    handleSelect(key) {
      if (key) {
        let number = Number(key)
        this.imageSrc = this.image[number];
        this.activeIndex = key;
      }
    },
    arrowClick(val) {
      if (val === 'right') {
        this.$refs.cardShow.next()
      } else {
        this.$refs.cardShow.prev()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.home-view {
  >p {
    text-align: center;
    font-weight: bold;
    margin-top: 0.5rem;
  }

  .bg-style {
    width: 100%;
    height: 8.77rem;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
    margin-top: 0.5rem;
    display: flex;

    &::before {
      content: "";
      display: table;
    }

    .title-style {
      color: #fff;
      font-size: 0.45rem;
      font-weight: 400;
      margin: auto;
    }
  }

  .customer-company {
    height: 6.81rem;
    margin-top: 0.7rem;

    img {
      width: 11rem;
      height: 3.88rem;
    }
  }


  .media-resource {
    margin-top: 0.5rem;
    width: 100%;
    height: 6.8rem;
    background-image: url('../assets/images/blue-bg.png');

    img {
      width: 11rem;
      height: 3.88rem;
    }

    p {
      text-align: center;
      font-weight: 600;
      color: #fff;
      margin: 0 auto;
      font-size: 0.24rem;
    }

  }

  .carousel-item1,
  .carousel-item2,
  .carousel-item3 {
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 10.81rem;
    color: #fff;
    position: relative;

    p {
      margin: 0;
    }

    >div {
      margin-top: 4rem;

      >p:nth-child(1) {
        font-size: 0.7rem;
        font-weight: 300;
      }

      >p:nth-child(2) {
        font-size: 0.7rem;
        font-weight: 300;
      }

      .arrow-style {
        position: absolute;
        bottom: -0.02rem;
        right: 0.8rem;

        img {
          width: 0.2rem;
          height: 0.2rem;
        }
      }
    }
  }

  .carousel-item1::before,
  .carousel-item2::before,
  .carousel-item3::before {
    display: table;
    content: "";
  }

  .solution-zh,
  .solution-en {
    position: absolute;
    z-index: 10;
    top: 0.3rem;
    left: 4.22rem;
  }
}

::v-deep .el-carousel__container {
  height: 10.8rem !important;
}

.solution-zh ::v-deep .el-menu-item,
.solution-en ::v-deep .el-menu-item {
  font-size: 0.16rem !important;
  width: 2.11rem;
  min-width: 60px;
  text-align: center;
}

.solution-zh ::v-deep .el-menu--horizontal>.el-menu-item.is-active,
.solution-en ::v-deep .el-menu--horizontal>.el-menu-item.is-active {
  @media screen and (min-width:600px) {
    border: 2px solid #fff;
    border-radius: 1rem;

  }
}

.el-carousel ::v-deep .el-carousel__indicator--horizontal .el-carousel__button {
  width: 0.1rem;
  height: 0.1rem;
  border-radius: 0.1rem;
}

::v-deep .el-carousel__indicators--horizontal {
  left: 6%;
}

::v-deep .el-carousel__indicator--horizontal .el-carousel__button {
  width: 0;
  height: 0;
}

::v-deep .el-carousel__arrow i.el-icon-arrow-right {
  background-image: url('@/assets/images/right-arrow.png');
}

::v-deep .el-carousel__arrow i.el-icon-arrow-left {
  background-image: url('@/assets/images/left-arrow.png');
}


//移动端
@media screen and (max-width:420px) {

  .home-view .solution-zh,
  .home-view .solution-en {
    left: 0.6rem;
  }

  .home-view .carousel-item1>div .arrow-style,
  .home-view .carousel-item2>div .arrow-style,
  .home-view .carousel-item3>div .arrow-style {
    bottom: 0.4rem;
  }

  .el-menu--horizontal>.el-menu-item.is-active {
    height: 50px;
  }

  .solution-zh ::v-deep .el-menu-item,
  .solution-en ::v-deep .el-menu-item {
    font-size: 0.3rem !important;
  }

}
</style>
