(function (d, w) {
  const doc = d.documentElement;
  function rem() {
    const width = doc.getBoundingClientRect().width;
    doc.style.fontSize = width / 1920 * 100 + 'px';
    if(width<450){
      doc.style.fontSize = width / 1200 * 100 + 'px';
    }
  }
  rem();
  w.addEventListener('resize', rem);
})(document, window);