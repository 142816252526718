import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import './style/main.css'
import element from './element/index'
import VueI18n from 'vue-i18n'
import i18n from './lang'
import axios from 'axios';
import './flex'
import VueRouter from 'vue-router'
import VueLazyload from "vue-lazyload";
Vue.use(element)
Vue.use(VueI18n)
Vue.use(VueRouter)
Vue.use(VueLazyload);
axios.defaults.timeout = 5000;
Vue.prototype.$axios = axios;
Vue.use(element,{
  size:'medium',
  i18n:(key,value)=>i18n.t(key,value)
})
 
// 解决ElementUI导航栏中重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
