import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: "/programmatic",
    component: () => import('@/views/solution/programmatic'),
    name: "programmatic",
  },
  {
    path: "/ua-audit",
    component: () => import('@/views/solution/ua-audit'),
    name: "ua-audit",
  },
  {
    path: "/creative-lab",
    component: () => import('@/views/solution/creative-lab'),
    name: "creative-lab",
  },
  {
    path: "/new-media",
    component: () => import('@/views/solution/new-media'),
    name: "new-media",
  },
  {
    path: "/monetization",
    component: () => import('@/views/solution/monetization'),
    name: "monetization",
  },
  {
    path: '/news-room',
    name: 'news-room',
    component: () => import('@/views/news/index'),
  },
  {
    path: "/bigo",
    component: () => import('@/views/news/bigo'),
    name: "bigo",
  },
  {
    path: "/kwai",
    component: () => import('@/views/news/kwai'),
    name: "kwai",
  },
  {
    path: "/miAds",
    component: () => import('@/views/news/miAds.vue'),
    name: "miAds",
  },
  {
    path: "/shortVideo",
    component: () => import('@/views/news/shortVideo.vue'),
    name: "shortVideo",
  },
  {
    path: "/shareChat",
    component: () => import('@/views/news/shareChat.vue'),
    name: "shareChat",
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: () => import('@/views/aboutus/AboutUs'),       
  },
  {
    path: "/join-us",
    component: () => import('@/views/aboutus/JoinUs'),
    name: "JoinUs",
  },
  {
    path: "/privacy-policy",
    component: () => import('@/views/aboutus/PrivacyPolicy'),
    name: "PrivacyPolicy",
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact/index')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
